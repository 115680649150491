.jukebox_button {
  --jukebox-button-height: 12rem;
  --jukebox-button-width: 9rem;

  height: var(--jukebox-button-height);
  width: var(--jukebox-button-width);
  background-color: var(--button-background);
  background-color: var(--button-background);
  border-radius: var(--button-radius);
  font-size: var(--button-font-size);
  color: var(--button-font-color);
  color: var(--button-font-color);
  box-shadow: 0px 10px var(--color-primary);
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0.5rem 1rem 0.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.jukebox_button > h1,
.jukebox_button > h2,
.jukebox_button > h3,
.jukebox_button > h4,
.jukebox_button > h5,
.jukebox_button > h6,
.jukebox_button > p,
.jukebox_button > a {
  color: var(--button-font-color);
}

.jukebox_button:disabled {
  cursor: not-allowed;
  opacity: var(--button-opacity-disabled);
}

.jukebox_button:active {
  box-shadow: 0px 5px var(--color-primary);
  top: 3px;
}

.jukebox_button:hover:active {
  box-shadow: 0px 6px var(--color-primary);
  top: 0px;
}

@media (min-width: 40em) {
  .jukebox_button {
    height: calc(var(--jukebox-button-height) * 1.25);
    width: calc(var(--jukebox-button-width) * 1.45);
    margin: 1rem 1.5rem 1rem 1.5rem;
  }
}

@media (min-width: 80em) {
}

@media (min-width: 120em) {
  .jukebox_button {
    height: calc(var(--jukebox-button-height) * 1.65);
    width: calc(var(--jukebox-button-width) * 1.85);
  }
}
