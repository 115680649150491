.cardBody {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}

.image_container {
  display: flex;
  width: 25%;
  padding: 0;
  flex-shrink: 0;
}

.content_container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0;
  overflow: hidden;
}

.button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 25%;
}

.title_heading {
  font-family: "Righteous", sans-serif;
  color: var(--sb-darkblue);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.artists_heading {
  color: var(--sb-darkblue);
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75%;
}

@media (min-width: 360px) {
  .title_heading {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .artists_heading {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .button_container {
    justify-content: flex-end;
  }

  .card_body {
    padding-right: 1.25rem;
  }
}

@media (min-width: 640px) {
  .image_container {
    width: 20%;
  }
}

@media (min-width: 768px) {
  .image_container {
    width: 15%;
  }
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
}
